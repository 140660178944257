.news-card {
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 200px;
  display: flex;
  flex-direction: column;
}

.news-card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card-description {
  color: #606060;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
  margin-bottom: auto; /* Empuja todo lo demás al fondo */
}

.news-card-action {
  position: absolute; /* Posicionamiento absoluto */
  bottom: 16px; /* Abajo del todo, dentro del padding */
  right: 16px; /* A la derecha del todo, dentro del padding */
  display: flex;
  align-items: center;
}

.news-card-date {
  margin-right: 20px;
  font-size: 11px;
  color: #6c757d;
}

.icon-button {
  cursor: pointer;
  color: #007bff;
  margin-right: 20px;
}

.icon-download  {
  cursor: pointer;
  color: #007bff;
  margin-right: 8px;
}

.news-card-type {
  padding: 6px 12px; /* Padding más grande para que coincida con el diseño */
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 8px; /* Espacio entre el icono y el tipo de noticia */
  background-color: #ffc107; /* Color por defecto, se cambiará con clases adicionales */
}

.icon-button {
  display: none; /* Oculta por defecto */
}

/* Colores para los tipos de noticias */
.success { background-color: #28a745; }
.primary { background-color: #007bff; }
.warning { background-color: #ffc107; }
.danger { background-color: #dc3545; }

@media (max-width: 1024px) {
  .news-card {
    /* Mantenemos la altura para no alterar el diseño general */
    height: 200px;
    margin: 5px; /* Un poco menos de margen para más espacio en pantalla */
  }

  .news-card-title {
    /* Ajustamos el tamaño del título para que sea un poco más grande que en móviles */
    font-size: 14px; 
  }

  .news-card-description {
    /* Aumentamos ligeramente el tamaño del texto para mejorar la legibilidad */
    font-size: 1rem;
    -webkit-line-clamp: 3; /* Podemos ajustar el número de líneas si es necesario */
  }

  .icon-button {
    display: inline; /* Muestra el botón en pantallas pequeñas */
  }
}

/* Estilos para tablets */
@media (max-width: 768px) {
  .news-card {
    margin: 5px;
    height: 200px; /* Altura más pequeña para ajustarse al contenido */
  }

  .news-card-title {
    font-size: 18px; /* Tamaño de texto más pequeño */
  }

  .news-card-description {
    -webkit-line-clamp: 4; /* Menos líneas de texto para ahorrar espacio */
    font-size: 1rem; /* Tamaño de texto más pequeño */
  }

  .icon-button {
    display: block; /* Cambia a 'block' para que se muestre el botón en pantallas pequeñas */
  }
}

/* Estilos para teléfonos en orientación horizontal y dispositivos pequeños */
@media (max-width: 600px) {
  .news-card {
    height: 200px; /* Altura más pequeña para dispositivos más pequeños */
  }

  .news-card-title {
    font-size: 18px;
  }

}

/* Estilos para teléfonos en orientación vertical */
@media (max-width: 480px) {
  .news-card {
    height: 200px; /* Ajuste de altura para pantallas estrechas */
  }
}
