.height-content {
  height: calc(100vh - 70px);
}

.text-danger-fill {
  fill: #fd397a;
}

.alert-xs {
  padding: 0.5rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  margin: 0.2rem !important;
  /* width: 50%; */
}

.txt-white {
  color: #fff !important;
}

.txt-dark {
  color: #181C32 !important;
}

/* ---------------------------------------------------- */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /* gutter size offset */
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  /* gutter size */
  padding-left: 30px;
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: transparent; */
  margin-bottom: 15px;
}

/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}

/* ---------------------------------------------------- */

/* Avatar  */
.kt-avatar {
  position: relative !important;
  display: inline-block !important;
}

.kt-avatar .kt-avatar__holder {
  width: 160px !important;
  height: 160px !important;
  border-radius: 3px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.kt-avatar .kt-avatar__holder-pdf {
  width: 269px !important;
  height: 172px !important;
  border-radius: 3px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.kt-avatar .kt-avatar__upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.kt-avatar .kt-avatar__upload input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

.kt-avatar .kt-avatar__upload i {
  position: absolute;
  color: #009ef7;
  font-size: 0.9rem;
}

.kt-avatar .kt-avatar__upload:hover {
  transition: all 0.3s;
  background-color: #009ef7;
}

.kt-avatar .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar .kt-avatar__cancel {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  right: -10px;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  display: none;
}

.kt-avatar .kt-avatar__cancel i {
  transition: all 0.3s;
  color: #74788d;
  font-size: 0.9rem;
}

.kt-avatar .kt-avatar__cancel:hover i {
  transition: all 0.3s;
  color: #009ef7;
}

.kt-avatar.kt-avatar--changed .kt-avatar__cancel {
  display: flex;
}

.kt-avatar__cancel--pdf {
  bottom: -40px !important; /* Ajusta según sea necesario */
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload i {
  color: #009ef7;
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover {
  background-color: #009ef7;
}

.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload:hover {
  background-color: #ffffff;
}

.kt-avatar.kt-avatar--light .kt-avatar__upload:hover i {
  color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload i {
  color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover {
  background-color: #282a3c;
}

.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload i {
  color: #5867dd;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover {
  background-color: #5867dd;
}

.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload i {
  color: #1dc9b7;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload:hover {
  background-color: #1dc9b7;
}

.kt-avatar.kt-avatar--success .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload i {
  color: #5578eb;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload:hover {
  background-color: #5578eb;
}

.kt-avatar.kt-avatar--info .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload i {
  color: #ffb822;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover {
  background-color: #ffb822;
}

.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover i {
  color: #111111;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload i {
  color: #fd397a;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover {
  background-color: #fd397a;
}

.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover i {
  color: #ffffff;
}

.kt-avatar.kt-avatar--circle .kt-avatar__holder {
  border-radius: 50%;
}

.kt-avatar.kt-avatar--circle .kt-avatar__upload {
  right: 0px;
  top: 5px;
}

.kt-avatar.kt-avatar--circle .kt-avatar__cancel {
  right: 3px;
  bottom: 10px;
}

.kt-avatar.kt-avatar--outline .kt-avatar__holder {
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
}

.kt-avatar .kt-avatar__save {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 10%);
  display: none;
}

.kt-avatar .kt-avatar__save:hover {
  color: #28c76f;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

/* Label Pantalla Noticias */

.news-card-type-other {
  padding: 6px 12px; /* Padding más grande para que coincida con el diseño */
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 8px; /* Espacio entre el icono y el tipo de noticia */
  background-color: #ffc107; /* Color por defecto, se cambiará con clases adicionales */
}

/* Estilos para teléfonos en orientación horizontal y dispositivos pequeños */
@media (max-width: 600px) {
  .news-card-type-other {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

}

.custom-modal-class {
  z-index: 99999 !important;
}

.vertical-tabs {
  display: flex;
  flex-direction: row;
}

.vertical-tabs .nav-tabs {
  flex-direction: column;
  border-right: 1px solid #dee2e6;
}

.vertical-tabs .nav-link {
  margin-right: 0;
  padding: 0.5rem 1rem;
}

.vertical-tabs .tab-content {
  flex-grow: 1;
}
